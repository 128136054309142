import React, { lazy, useState } from "react";
import { Grid2, Button, Avatar, styled } from "@mui/material";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { useDispatch, useSelector } from "react-redux";
import { EnableComponentHierachy } from "../../redux/component/actions";
import { IModel } from "../../utilities/types/Model";
import AddCategoryIcon from "@mui/icons-material/AddCircleOutline";
import { selectorGetModelById } from "../../redux/model/selectors";
import { RootState } from "../../redux";

import chatgptLogo from "../../assets/icons/chatgpt.svg";
import SuspenseWrapper from "../generic/SuspenseWrapper";

const LazyModalAiSuggestFailureMode = lazy(() => import("./modals/ModalAiSuggestFailureMode"));
const LazyModalFailureModeCreate = lazy(() => import("./modals/ModalFailureModeCreate"));

const GridWrapper = styled(Grid2)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  alignItems: "center",
  "& .addComponentButton": {
    color: theme.palette.primary.main,
    fontWeight: 400,
    textTransform: "capitalize",
    transition: "background 200ms ease-out",
    width: "100%",
  },
  "& .btnWrapper": {
    display: "flex",
    alignItems: "stretch",
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  "& .aiButton": {
    flexShrink: 0,
  },
  "& .icon": {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
}));

type IFMModelNewFMProps = {
  modelId: string;
  componentId: string;
  functionId: string;
  functionalFailureId: string;
};

const ModelBuilderNewFailureMode = ({ modelId, componentId, functionId, functionalFailureId }: IFMModelNewFMProps) => {
  const [showCreateFailureModeModal, setShowCreateFailureModeModal] = useState(false);
  const [loading] = useState(false);
  const dispatch = useDispatch();
  const [showAiSuggestModal, setShowAiSuggestModal] = useState<boolean>(false);
  const model = useSelector((store: RootState) => selectorGetModelById(store, modelId));

  function toggleShowCreateFailureModeModal() {
    setShowCreateFailureModeModal(!showCreateFailureModeModal);
  }

  function onCompleteCallback() {
    dispatch(EnableComponentHierachy({ model: model as IModel }));
    setShowCreateFailureModeModal(!showCreateFailureModeModal);
  }

  const toggleShowAiSuggestModal = () => setShowAiSuggestModal((prev) => !prev);
  const onCompleteAiSuggestCallback = () => toggleShowAiSuggestModal();

  return (
    <GridWrapper container>
      <Grid2 size={{xs:12}}>
        <div className="btnWrapper">
          <Button
            id="btn-create-function"
            variant="outlined"
            className="addComponentButton"
            onClick={toggleShowCreateFailureModeModal}
          >
            <AddCategoryIcon style={{ marginRight: "8px" }} /> Add a new failure mode
          </Button>
          <Button id="btn-create-component" variant="outlined" className="aiButton" onClick={toggleShowAiSuggestModal}>
            <Avatar src={chatgptLogo} className="icon" />
          </Button>
        </div>
      </Grid2>
      <SuspenseWrapper showFullLoader>
        <LazyModalFailureModeCreate
          open={showCreateFailureModeModal}
          onCancelCallback={toggleShowCreateFailureModeModal}
          onCompleteCallback={onCompleteCallback}
          modelId={model?.modelId ?? ""}
          functionalFailureId={functionalFailureId}
        />
      </SuspenseWrapper>
      <SuspenseWrapper showFullLoader>
        <LazyModalAiSuggestFailureMode
          open={showAiSuggestModal}
          onCancelCallback={toggleShowAiSuggestModal}
          onCompleteCallback={onCompleteAiSuggestCallback}
          modelId={model?.modelId ?? ""}
          functionId={functionId}
          functionalFailureId={functionalFailureId}
          componentId={componentId}
        />
      </SuspenseWrapper>
      <LoaderAbsoluteCentred loading={loading} />
    </GridWrapper>
  );
};

export default ModelBuilderNewFailureMode;
