import { IComponent } from "../../utilities/types/Component";
import { useFileSourceSingleHook } from "../file/Hooks";
import { FileTypeEnum } from "../../utilities/types/File";
import React, { useState } from "react";
import { Grid2, Typography, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import IdentifierMappingsRenderer from "../identifierMapping/IdentifierMappingsRenderer";
import { IdentifierMappingTypeEnum } from "../../utilities/types/IdentifierMapping";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import AddFunctionIcon from "@mui/icons-material/FunctionsOutlined";
import EditComponentIcon from "@mui/icons-material/Edit";
import ModalFunctionCreate from "../function/modals/ModalFunctionCreate";
import { useDispatch, useSelector } from "react-redux";
import { EnableComponentHierachy } from "../../redux/component/actions";
import { IModel, ModelStatusEnum } from "../../utilities/types/Model";
import { RootState } from "../../redux";
import { selectorGetModelById } from "../../redux/model/selectors";
import DiscussionsIcon from "../discussion/DiscussionIcon";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import {
  selectorGetUserPermissionOfType,
} from "../../redux/userPermission/selectors";
import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";
import { GetUserId } from "../../utilities/ApiUtils";

const imageDiameter = 100;

const GridComponentWrapper = styled(Grid2)(({ theme }) => ({
  border: "1px solid #EEE",
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  "& .componentImageWrapper": {
    width: imageDiameter,
    maxWidth: "8.3%",
    textAlign: "center",
    display: "inline-block",
    position: "absolute",
    left: 0,
    bottom: 0,
    top: 0,
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    overflow: "hidden",
    borderRight: "rgba(0,0,0,0.1)",
  },
  "& .componentImage": {
    objectFit: "cover",
    filter: "sepia(20%)",
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  "& .componentMainWrapper": {
    marginLeft: theme.spacing(1),
  },
  "& .greenIcon": {
    color: theme.palette.primary.main,
  },
  "& .orangeDraft": {
    color: "orange",
  },
}));

interface IModelBuilderComponentProps {
  component: IComponent;
  canEdit: boolean;
  onUploadComplete(componentId: string): void;
  onComponentClick(component: IComponent): void;
  canDiscuss?: boolean;
}

function ModelBuilderComponent({ component, canEdit, onComponentClick, canDiscuss }: IModelBuilderComponentProps) {
  const imageUrl = useFileSourceSingleHook({ fileId: component.mainImageId || "", fileType: FileTypeEnum.Image });
  const [loading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [showAddFunctionModal, setShowAddFunctionModal] = useState(false);
  const dispatch = useDispatch();
  const model = useSelector((store: RootState) => selectorGetModelById(store, component.modelId));
  const canViewDiscussions = useSelector((state: RootState) =>
    selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesDiscussionRead)
  );
  const canEditComponents = useSelector((state: RootState) =>
  selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesComponentUpdate)
);
const canAddFunctions = useSelector((state: RootState) =>
selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesFunctionCreate)
);


  async function toggleShowAddFunctionModal() {
    setMenuAnchorEl(undefined);
    setShowAddFunctionModal(!showAddFunctionModal);
  }

  async function onComponentClickWrapper() {
    setMenuAnchorEl(undefined);
    onComponentClick(component);
  }

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  function onCreateCallback() {
    setMenuAnchorEl(undefined);
    setShowAddFunctionModal(!showAddFunctionModal);
    dispatch(EnableComponentHierachy({ model: model as IModel }));
  }

  return (
    <GridComponentWrapper container>
      <Grid2 size={{xs:1}}>
        <div className="componentImageWrapper">
          <div className="componentImage" style={{ backgroundImage: `url("${imageUrl}")` }} />
        </div>
      </Grid2>
      <Grid2 size={{xs:10}} style={{ paddingLeft: 8 }}>
        <div className="componentMainWrapper">
          {component.status === ModelStatusEnum.Draft ? (
            <Typography className="orangeDraft">{component.name} (Draft)</Typography>
          ) : (
            <Typography variant="body1">{component.name}</Typography>
          )}
          {component.status === ModelStatusEnum.Draft ? (
            <Typography variant="caption">{component.description} (Draft)</Typography>
          ) : (
            <Typography variant="caption">{component.description}</Typography>
          )}
        </div>

        <div>
          {canViewDiscussions ? (
            <DiscussionsIcon
              discussionCount={component.discussionCount}
              entityId={component.componentId}
              entityType={EntityTypeEnum.Component}
              canDiscuss={canDiscuss}
              model={model}
            />
          ) : null}
          <IdentifierMappingsRenderer
            type={IdentifierMappingTypeEnum.Component}
            targetId={String(component.componentId)}
            modelId={component.modelId}
            canEdit={canEdit}
            loading={false}
            showValues={false}
          />
        </div>
      </Grid2>
      <Grid2 size={{xs:1}} style={{ textAlign: "right" }}>
        {canEdit ? (
          <>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
              <EditComponentIcon />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(undefined)}
            >
              {canAddFunctions ? (
                <MenuItem onClick={toggleShowAddFunctionModal}>
                  <ListItemIcon>
                    <AddFunctionIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Add function" />
                </MenuItem>
              ) : null}
              {canEditComponents ? (
                <MenuItem onClick={onComponentClickWrapper}>
                  <ListItemIcon>
                    <EditComponentIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Edit component" />
                </MenuItem>
              ) : null}
            </Menu>
          </>
        ) : null}
      </Grid2>

      <LoaderAbsoluteCentred loading={loading} />
      <ModalFunctionCreate
        open={showAddFunctionModal}
        componentId={component.componentId}
        modelId={component.modelId}
        onCancelCallback={toggleShowAddFunctionModal}
        onCompleteCallback={onCreateCallback}
      />
    </GridComponentWrapper>
  );
}

export default ModelBuilderComponent;
