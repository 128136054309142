export interface ISolverJob {
  solverJobId: string;
  solverId: string;
  name?: string;
  modelId: string;
  status: SolverJobStatusEnum;
  started: Date;
  ended?: Date;
  solverCount: number;
  solversGenerated: number,
  solversCompleted: number,
  fileId: string;
  progressLog: string;
  persistentErrorMessage: string;
  prerequisites: {
    assetJobIds: string[];
    outputJobIds: string[];
    solverJobIds: string[];
  };
  userId: string;
  created: Date;
  updated: Date;
  createdBy: string;
  updatedBy: string;
}

export enum SolverJobStatusEnum {
  Pending = 10,
  Progress = 20,
  Failed = 30,
  Complete = 40,
  Cancelled = 50,
  Generating = 60,
  GeneratingInProgress = 70,
}

export type TSolverInputFieldValue = {
  value?: number;
  valueString?: string;
  valueDateTime?: Date;
  solverInputFieldValueId?: string;
};
