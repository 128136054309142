import React, { useState } from "react";
import { Grid2, Button, styled } from "@mui/material";

import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { useDispatch, useSelector } from "react-redux";
import { EnableComponentHierachy } from "../../redux/component/actions";
import { IModel } from "../../utilities/types/Model";
import ModalTaskCreate from "./modals/ModalTaskCreate";
import { selectorGetModelById } from "../../redux/model/selectors";
import { RootState } from "../../redux";

const DivWrapper = styled(Grid2)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  alignItems: "center",
  "& .addComponentButton": {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textTransform: "capitalize",
    marginRight: theme.spacing(3),
    transition: "background 200ms ease-out",
    width: "100%",
  },
  "& .btnAddTask": {
    display: "flex",
    flexGrow: 1,
    marginLeft: "auto",
    marginRight: 0,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
}));

type ITaskModelNewTaskProps = {
  modelId: string;
};

const ModelBuilderNewTask = ({ modelId }: ITaskModelNewTaskProps) => {
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);

  const [loading] = useState(false);
  const dispatch = useDispatch();
  const model = useSelector((store: RootState) => selectorGetModelById(store, modelId));

  function toggleShowCreateTaskModal() {
    setShowCreateTaskModal(!showCreateTaskModal);
  }

  function onCompleteCallback() {
    dispatch(EnableComponentHierachy({ model: model as IModel }));
    setShowCreateTaskModal(!showCreateTaskModal);
  }

  return (
    <DivWrapper>
        <Button color="primary" variant="outlined" className="btnAddTask" onClick={toggleShowCreateTaskModal}>
                  Add Task
        </Button>
      <ModalTaskCreate
        open={showCreateTaskModal}
        onCancelCallback={toggleShowCreateTaskModal}
        onCompleteCallback={onCompleteCallback}
        modelId={model?.modelId ?? ""}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </DivWrapper>
  );
};

export default ModelBuilderNewTask;
