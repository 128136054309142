import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import FormFunctionalFailureCreate from "../forms/FormFunctionalFailureCreate";
import { IFunctionalFailure } from "../../../utilities/types/FunctionalFailure";
import { DivWrapper } from "./ModalFunctionalFailureUpdate";
import FunctionIcon from "@mui/icons-material/FunctionsOutlined";
export interface IModalFunctionalFailureCreateProps {
  open: boolean;
  onCancelCallback(): void;
  modelId: string;
  functionId?: string;
  onCompleteCallback(functionalFailure?: IFunctionalFailure): void;
}

function ModalFunctionalFailureCreate({
  onCancelCallback,
  open,
  functionId,
  onCompleteCallback,
  modelId,
}: IModalFunctionalFailureCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create functional failure"}
      subtitle={"Fill in the form to complete. Use ',' to seperate names"}
      headerIcon={<DivWrapper><div className="iconWrapper">
        <FunctionIcon />
      </div></DivWrapper>}
    >
      <DialogContent>
        <FormFunctionalFailureCreate
          functionId={functionId}
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalFunctionalFailureCreate;
