import React from "react";
import { TextField, Grid2, Divider, Button } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { ISolverHistory } from "../../../utilities/types/SolverHistory";
import { fetchCreateSolverHistory } from "../../../redux/solverHistory/actions";
import { MessageTypeEnum } from "../../../utilities/types/Message";

interface FormValues {
  title: string;
  description: string;
  versionNumber: number;
  solverId: string;
}

interface FormProps {
  versionNumber?: number;
  solverId: string;
  onCompleteCallback(solverHistory?: ISolverHistory): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ISolverHistory, AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
      <Grid2 size={{xs:12, sm:6}}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "title", "Title")}
            fullWidth
            margin="normal"
            variant="standard"
          />
        </Grid2>

      <Grid2 size={{xs:12, sm:6}}>
          <TextField
            onChange={props.handleChange}
            type="number"
            fullWidth
            margin="normal"
            variant="standard"
            {...getFormikFieldProps(props, "versionNumber", "Version Number")}
          />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <TextField
            onChange={props.handleChange}
            multiline
            rows={3}
            fullWidth
            margin="normal"
            variant="standard"
            {...getFormikFieldProps(props, "description", "Description")}
          />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>
        <Grid2 size={{xs:12}} display={'flex'} justifyContent={'flex-end'} gap={2}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormSolverCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: (props) => {
    return {
      title: "",
      description: "",
      versionNumber: props.versionNumber || 1,
      solverId: props.solverId,
    };
  },
  validationSchema: Yup.object().shape({
    title: Yup.string()
      .label("Title")
      .min(3, "Please input 3 characters or more")
      .max(50, "Please input 50 characters or less")
      .required("Please input login name"),
    description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
    versionNumber: Yup.number().label("Version Number").min(0, "Zero or more.").max(999, "Less than 1000"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, solverId } = props;

    var message = await ShowMessage("Saving history", MessageTypeEnum.Information);

    // Map dispatch via props
    var solverHistory = await dispatch(
      fetchCreateSolverHistory({
        ...values,
        solverId,
      })
    );

    UpdateMessage({ ...message, text: "History saved", type: MessageTypeEnum.Success });
    setSubmitting(false);
    onCompleteCallback(solverHistory);
  },
})(InnerForm);

export default FormSolverCreate;
