import React, { useState } from "react";
import { Container, Grid2, Breadcrumbs, Link, styled } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";

import ModelVersionHistory from "../ModelDetailsSummary";
import ModelBuilderMainTabsContainer from "../ModelBuilderMainTabsContainer";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import {
  selectorGetUserPermissionIsAdmin,
  selectorGetUserPermissionOfType,
} from "../../../redux/userPermission/selectors";
import { selectorGetModelById } from "../../../redux/model/selectors";
import { GetUserId } from "../../../utilities/ApiUtils";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import HeaderIcon from "@mui/icons-material/AccountTree";
import { UserPermissionTypeEnum } from "../../../utilities/types/UserPermission";
import { ModelStatusEnum } from "../../../utilities/types/Model";
import ModelValidationSection from "../ModelValidationSection";
import { HomeIconLink } from "../../generic/HomeIconLink";
import AssetCollapseCard from "../AssetCollapseCard";

const GridWrapper = styled(Grid2)(({ theme }) => ({
  marginTop: `${theme.spacing(2)} !important`,
}));

interface IPageModelDetailsProps extends RouteComponentProps<any> {
  handleRefetchModal: () => void;
}

const PageModelDetails = (routeProps: IPageModelDetailsProps) => {
  const [showCollapse, setShowCollapse] = useState<boolean>(false);
  const {
    match: {
      params: { modelId },
    },
    handleRefetchModal,
  } = routeProps;
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state));
  const model = useSelector((store: RootState) => selectorGetModelById(store, modelId));
  const canEdit = isAdmin 
  || (model && model.createdBy === GetUserId() && (model.status === ModelStatusEnum.Private || model.status === ModelStatusEnum.Draft)) ? true : false;
  const hasModelEditPermission = useSelector((state: RootState) =>
    selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesModelUpdate)
  );

  if (!model) {
    let description = !canEdit && !hasModelEditPermission ? "View details permission may be required." : "";
    return (
      <Container maxWidth="xl" style={{ marginTop: 16 }}>
        <WidgetNoResultsPlaceholder text="Model not found" description={description} fade={true} icon={HeaderIcon} />
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <GridWrapper size={{xs:12}}>
        <Breadcrumbs aria-label="breadcrumb">
          <HomeIconLink />
          <Link color="inherit" href="/search">
            Search
          </Link>
          <Link
            color="textPrimary"
            aria-current="page"
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            {model?.title || "Model"}
          </Link>
        </Breadcrumbs>
      </GridWrapper>

      <AssetCollapseCard model={model} showCollapse={showCollapse} setShowCollapse={setShowCollapse} canEdit={canEdit}>
        <Grid2 container style={{ padding: 16 }}>
         <Grid2 size={{xs:12, md:6}}>
            <ModelVersionHistory modelId={modelId} />
          </Grid2>
         <Grid2 size={{xs:12, md:6}}>
            <ModelValidationSection modelId={modelId} handleRefetchModal={handleRefetchModal} />
          </Grid2>
        </Grid2>
      </AssetCollapseCard>

      <Grid2 container spacing={2} style={{ marginTop: 16 }}>
        <Grid2 size={{xs:12}}>
          <ModelBuilderMainTabsContainer modelId={modelId} />
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default PageModelDetails;
