import React, { lazy, useState } from "react";
import { Chip, styled } from "@mui/material";

import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { IdentifierMappingTypeEnum, IIdentifierMapping } from "../../utilities/types/IdentifierMapping";
import { selectorGetIdentifierMappingsByTargetAndTypeId } from "../../redux/identifierMapping/selectors";
import IdentifierMappingRenderer from "./IdentifierMappingRenderer";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { selectorGetUserPermissionOfType } from "../../redux/userPermission/selectors";
import { GetUserId } from "../../utilities/ApiUtils";
import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";
import SuspenseWrapper from "../generic/SuspenseWrapper";

const LazyModalIdentifierMappingCreate = lazy(() => import("./modals/ModalIdentifierMappingCreate"));

const DivWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(0.5),
  marginLeft: 0,
  display: "inline-block",
}));

type IIdentifierMappingsRendererProps = {
  canEdit: boolean;
  modelId: string;
  secondaryTargetId?: string;
  type: IdentifierMappingTypeEnum;
  targetId: string;
  loading: boolean;
  showValues: boolean;
};

const IdentifierMappingsRenderer = (props: IIdentifierMappingsRendererProps) => {
  const { targetId, type, secondaryTargetId } = props;
  const identifierMappings = useSelector((store: RootState) =>
    selectorGetIdentifierMappingsByTargetAndTypeId(store, targetId, type, secondaryTargetId)
  );

  return <IdentifierMappingsRendererDisplay {...props} identifierMappings={identifierMappings} />;
};

interface IIdentifierMappingsRendererDisplayProps extends IIdentifierMappingsRendererProps {
  identifierMappings: IIdentifierMapping[];
}

function IdentifierMappingsRendererDisplay(props: IIdentifierMappingsRendererDisplayProps) {
  const canViewIdentifierMappings = useSelector((state: RootState) =>
    selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesIdentifierMappingRead)
  );
  const canDeleteIdentifierMappings = useSelector((state: RootState) =>
    selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesIdentifierMappingDelete)
  );
  const canCreateIdentifierMappings = useSelector((state: RootState) =>
    selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesIdentifierMappingCreate)
  );

  const { identifierMappings, targetId, type, modelId, secondaryTargetId, showValues } = props;

  const [loading] = useState(false);
  const [showCreateMappingModal, setShowCreateMappingModal] = useState(false);

  function toggleCreateMappingModal() {
    setShowCreateMappingModal(!showCreateMappingModal);
  }

  if (!canViewIdentifierMappings) return null;

  return (
    <>
      {identifierMappings.map((identifierMapping) => {
        return (
          <DivWrapper key={identifierMapping.identifierMappingId} onClick={(e) => e.stopPropagation()}>
            <IdentifierMappingRenderer
              identifierMapping={identifierMapping}
              canEdit={canDeleteIdentifierMappings}
              showValue={showValues}
            />
          </DivWrapper>
        );
      })}

      {canCreateIdentifierMappings && (
        <DivWrapper onClick={(e) => e.stopPropagation()}>
          <Chip
            label="Add"
            icon={<AddIcon />}
            onClick={toggleCreateMappingModal}
            size="small"
            variant="outlined"
          />
        </DivWrapper>
      )}

      {canCreateIdentifierMappings && (
        <SuspenseWrapper showFullLoader>
          <LazyModalIdentifierMappingCreate
            open={showCreateMappingModal}
            onCancelCallback={toggleCreateMappingModal}
            onCompleteCallback={toggleCreateMappingModal}
            targetId={targetId}
            modelId={modelId}
            secondaryTargetId={secondaryTargetId}
            identifierMappingType={type}
          />
        </SuspenseWrapper>
      )}
      <LoaderAbsoluteCentred loading={loading} />
    </>
  );
}

export default IdentifierMappingsRenderer;
