import { useState, useMemo, useCallback, ChangeEvent } from 'react';
import { debounce } from 'lodash';

const useDebouncedSearch = (initialValue: string = '', delay: number = 300) => {
  const [searchText, setSearchText] = useState<string>(initialValue);


  const debouncedSetSearchText = useMemo(() => {
    return debounce((text: string) => setSearchText(text), delay);
  }, [delay]);


  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchText(event.target.value);
  }, [debouncedSetSearchText]);

  return {
    searchText,
    handleChange
  }
}

export default useDebouncedSearch