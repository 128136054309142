import React, { useState } from "react";
import { Grid2, Button, styled } from "@mui/material";

import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import AddCategoryIcon from "@mui/icons-material/AddCircleOutline";
import ModalApplicabilityCreate from "./modals/ModalTaskApplicabilityCreate";

const GridWrapper = styled(Grid2)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  alignItems: "center",
  "& .addComponentButton": {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textTransform: "capitalize",
    marginRight: theme.spacing(3),
    transition: "background 200ms ease-out",
    width: "100%",
  },
}));

type INewTaskApplicabilityProps = {
  taskId?: string;
  modelId?: string;
};

const NewTaskApplicability = ({ taskId, modelId }: INewTaskApplicabilityProps) => {
  const [showCreateApplicabilityModal, setShowCreateApplicabilityModal] = useState(false);
  const [loading] = useState(false);

  function toggleShowCreateApplicabilityModal() {
    setShowCreateApplicabilityModal(!showCreateApplicabilityModal);
  }

  function onCompleteCallback() {
    setShowCreateApplicabilityModal(!showCreateApplicabilityModal);
  }

  return (
    <GridWrapper container>
      <Grid2 size={{xs:12}}>
        <Button
          id="btn-create-applicability"
          variant="outlined"
          className="addComponentButton"
          onClick={toggleShowCreateApplicabilityModal}
        >
          <AddCategoryIcon style={{ marginRight: "8px" }} /> Suggest a new Applicability
        </Button>
      </Grid2>
      <ModalApplicabilityCreate
        open={showCreateApplicabilityModal}
        onCancelCallback={toggleShowCreateApplicabilityModal}
        onCompleteCallback={onCompleteCallback}
        taskId={taskId ?? ""}
        modelId={modelId ?? ""}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </GridWrapper>
  );
};

export default NewTaskApplicability;
