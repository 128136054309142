import React, { useState } from "react";
import { TextField, Grid2, Divider, Button, FormControl, InputLabel,  Select, MenuItem, Box } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch } from "react-redux";
import { IIdentifier } from "../../../utilities/types/Identifier";
import { fetchUpdateIdentifier, fetchDeleteIdentifier } from "../../../redux/identifier/actions";
import { Colors } from "../../../utilities/Constants";
import { MuiColorInput } from 'mui-color-input'
interface FormValues {
  name: string;
  code: string;
  color: string;
}

interface FormProps {
  identifier: IIdentifier;
  onCompleteCallback(identifier?: IIdentifier): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IIdentifier, AnyAction>;
}

const FormIdentifierUpdate = (formProps: FormProps) => {
  const {
    onCancelCallback,
    identifier: { identifierId },
  } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, identifier } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var updatedIdentifier = await dispatch(
      fetchUpdateIdentifier({
        ...values,
        identifierId: identifier.identifierId,
        orderNumber: 0,
      })
    );

    setSubmitting(false);
    if (updatedIdentifier) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedIdentifier);
    }
  };

  return (
    <Formik
      initialValues={{
        ...formProps.identifier,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteIdentifier({ identifierId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={identifierId} name="identifierId" />
            <Grid2 container spacing={2}>
              <Grid2 size={{xs:12}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "code", "Code Label")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <FormControl fullWidth={true}>
                  <InputLabel id="identifier-color" variant="standard">
                    Select Color
                  </InputLabel>
                  <Select
                    labelId="identifier-color"
                    {...getFormikFieldProps(props, "color", "Color")}
                    onChange={(e) => {
                      props.setFieldValue("color", e.target.value);
                    }}
                    style={{ display: "block" }}
                    variant="standard"
                  >
                    {Colors.map(({ name, hex }) => (
                      <MenuItem id={hex} value={`${hex}CC`}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box mt={3} mb={2}>
                  <Divider>OR</Divider>
                </Box>
             <InputLabel sx={{mb:1,fontSize:16}}  id="identifier-color" variant="standard">
                    Select Color
                  </InputLabel>
                <MuiColorInput fullWidth format="hex8" 
                  value={props.values.color||"#00000000"}
                  onChange={(newColor) => props.setFieldValue("color", newColor)}
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <TextField
                  onChange={props.handleChange}
                  multiline
                  rows={3}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "name", "Name")}
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <Divider light={true} />
              </Grid2>
              <Grid2 size={{xs:12}}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ flexBasis: "33%" }}
                    onClick={toggleDeleteConfirmation}
                  >
                    Delete
                  </Button>
                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      type="submit"
                      disabled={submitting}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 16 }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting || deleting} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete identifier"
                  subtitle="Confirm identifier delete"
                  description="Are you sure that you'd like to remove this identifier?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />
              </Grid2>
            </Grid2>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          code: Yup.string()
            .label("Code")
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less")
            .required("Please provide a code label"),
          name: Yup.string().label("Name").max(500, "Please input 500 characters or less"),
        });
      }}
    />
  );
};

export default FormIdentifierUpdate;
